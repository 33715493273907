import { FC } from "react";
import ContentLayout from "components/layouts/ContentLayout";

const Dashboard: FC = () => {
    return (
        <ContentLayout>
            <div>
                Dashboard Content
            </div>
        </ContentLayout>
    );
};

export default Dashboard;
