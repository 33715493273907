import {DownOutlined, HomeOutlined, WhatsAppOutlined} from "@ant-design/icons";
import {Avatar, Dropdown, Layout, Menu, MenuProps, Space} from "antd";
import {FC, useCallback, useEffect, useState} from "react";
import {Link, Outlet} from "react-router-dom";
import { DashboardLayoutContext } from "./context";
import {useAuth0} from "@auth0/auth0-react";


const UserMenuItems = ({ logout }: { logout: () => void}): MenuProps['items'] => ([
    { label: "Profile", key: "0" },
    { label: "Settings", key: "1" },
    { type: "divider" },
    { label: (<button onClick={logout}>Log Out</button>), key: "3" },
]);

const MainMenuItems: MenuProps['items'] = [
    {
        label: (
          <Link to="/">
              Dashboard
          </Link>
        ),
        key: 'dashboard',
        icon: <HomeOutlined />
    },
    {
        label: (
          <Link to="whatsapp">
              WhatsApp
          </Link>
        ),
        key: 'whatsapp',
        icon: <WhatsAppOutlined />
    }
]

const DashboardLayout: FC = () => {
    const [pathname, setPathname] = useState<string>('');
    const {user, isAuthenticated, isLoading, logout} = useAuth0();
    const selectedKeys = pathname.split('.');

    useEffect(() => {}, [isAuthenticated, isLoading]);

    const handleLogout = useCallback(() => {

        logout({ returnTo: window.location.origin });
    }, [logout]);

    return (
        <Layout>
            <Layout.Sider breakpoint="lg" collapsedWidth="0" className="h-screen !absolute md:!fixed md:!w-52 !max-w-none z-10">
                <Menu
                  theme="dark"
                  mode="inline"
                  defaultSelectedKeys={[...selectedKeys]}
                  selectedKeys={[...selectedKeys]}
                  items={MainMenuItems}
                />
            </Layout.Sider>
            <Layout className="ml-0 md:ml-52 !min-h-screen ">
                <Layout.Header className="!bg-white flex justify-end items-center ">
                    <div className="flex gap-4 items-center ">
                        <div className="leading-6">
                            <Avatar src={user?.picture} />
                        </div>
                        <div className="leading-6">
                            <Dropdown overlay={<Menu items={UserMenuItems({ logout: handleLogout })} />} trigger={['click']}>
                                <a onClick={e => e.preventDefault()}>
                                    <Space className="leading-6">
                                        <div className="flex flex-col h-full">
                                            <span className="text-base">{user?.name}</span>
                                            <span className="text-xs ">{user?.email}</span>
                                        </div>
                                        <DownOutlined />
                                    </Space>
                                </a>
                            </Dropdown>
                        </div>
                    </div>
                </Layout.Header>
                <Layout.Content>
                    <DashboardLayoutContext.Provider value={{ setPathname }}>
                        <Outlet />
                    </DashboardLayoutContext.Provider>
                </Layout.Content>
                <Layout.Footer>
                    Multileaf &copy; {new Date().getFullYear()} Created by Multileaf Team
                </Layout.Footer>
            </Layout>
        </Layout>
    )
};

export default DashboardLayout;
