import React, {useCallback, useState} from "react";
import {Input} from "antd";

interface PhoneNumberInputProps {
  value?: any;
  onChange?: (value: any) => void;
}
const PhoneNumberInput: React.FC<PhoneNumberInputProps> = ({ value, onChange}) => {
  const [phone, setPhone] = useState<string>('');

  const handleChange = useCallback(($event: React.ChangeEvent<HTMLInputElement>) => {
    const numberValue = $event.target.value.replace(/[^0-9]/g, '');
    setPhone(numberValue);
    onChange?.(numberValue);
  }, [setPhone, onChange]);

  const handleBlur = useCallback(($event: React.FocusEvent<HTMLInputElement>) => {
    const phoneValue = $event.target.value;
    const numberValue = /^[0-9]{9}$/.test(phoneValue) ? `51${phoneValue}` : phoneValue;
    setPhone(numberValue);
    onChange?.(numberValue);
  }, [setPhone, onChange]);

  return <Input type="tel" value={value || phone} onChange={handleChange} onBlur={handleBlur}/>
};

export default PhoneNumberInput;
