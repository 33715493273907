import DashboardLayout from './components/layouts/DashboardLayout';
import { Route, Routes } from 'react-router-dom';
import Dashboard from './site/pages/Dashboard';
import Whatsapp from './site/pages/Whatsapp';
import Login from "./site/pages/Login";
import './App.css';
import Root from "./site/pages/Root";

function App() {
  return (
    <>
      <Routes>
        <Route path="/" element={<Root />}>
          <Route path="/app" element={<DashboardLayout />}>
            <Route index element={<Dashboard />} />
            <Route path="whatsapp" element={<Whatsapp />} />
          </Route>
          <Route path="/login" element={<Login />} />
        </Route>s
      </Routes>
    </>
  );
}

export default App;
