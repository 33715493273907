import {useAuth0} from "@auth0/auth0-react";
import {useCallback} from "react";
import {Button, Typography} from "antd";
import {SyncOutlined} from "@ant-design/icons";
import LoginConfirm from "./LoginConfirm";

const Login = () => {
  const { loginWithPopup, isAuthenticated, isLoading, user, logout } = useAuth0();

  const handleLogin = useCallback(async () => {
    try {
      await loginWithPopup();
    } catch (err) {
      console.log(err);
    } finally {
      console.log("Completed");
    }
  }, []);


  if (isLoading) {
    return (
      <div className="w-screen h-screen flex items-center">
        <SyncOutlined spin className="m-auto text-2xl" />
      </div>
    )
  }

  const existAuthUser = !isLoading && isAuthenticated;

  return (
    <div className="w-screen h-screen flex items-center">
      <div className="shadow-lg w-96 p-6 m-auto">
        {existAuthUser ?
          <LoginConfirm user={user} logout={logout} /> :
          (
            <>
              <Typography.Text type="secondary" className="block mb-4 p-2 text-center">You are not authenticated, please login to continue.</Typography.Text>
              <Button type="primary" onClick={handleLogin} loading={isLoading} className="w-full !h-auto !p-2 !rounded">
                Login
              </Button>
            </>

          )
        }
      </div>

    </div>
  );
};

export default Login;
