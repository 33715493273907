import {Avatar, Button, Typography} from "antd";
import {User} from "@auth0/auth0-react";
import {FC, useCallback} from "react";
import {LogoutOptions} from "@auth0/auth0-spa-js";
import {useNavigate} from "react-router-dom";

interface LoginConfirmProps {
  user: User | undefined;
  logout: (options?: LogoutOptions) => void;
}

const LoginConfirm: FC<LoginConfirmProps> = ({ user, logout }) => {
  const navigate = useNavigate();

  const handleLogout = useCallback(() => {
    logout({ returnTo: window.location.origin });
  }, [logout]);

  const handleRedirect = useCallback(() => {
    navigate("/app");
  }, []);

  return (
    <>
      <Typography.Text type="secondary" className="block mb-4 p-2 text-center">You are authenticated as</Typography.Text>
      <div className="flex flex-col justify-center items-center">
        <Avatar size={64} src={user?.picture} />
        <Typography.Title level={3} className="my-2 px-6 text-center">
          {user?.name}
        </Typography.Title>
        <Button type="primary" onClick={handleRedirect} className="w-full !h-auto !p-2 my-2 !rounded">
          Continue with this account
        </Button>
        <Button type="link" onClick={handleLogout} className="w-full !h-auto !p-2 my-2 !rounded">
          Use different account
        </Button>
      </div>

    </>
  );
};

export default LoginConfirm;
