import { pipe, replace, toPairs, trim } from "ramda";
import { FC, useContext, useEffect } from "react";
import { Outlet, useLocation, useParams } from "react-router-dom";
import { DashboardLayoutContext } from "../DashboardLayout/context";
import { ReplaceItemFn } from "../DashboardLayout/model";
import { IContentLayout } from "./model";


const replaceItem: ReplaceItemFn = (pathname, [key, value]) => pathname.replace(value, key);
const reducePath = (replaceFn: ReplaceItemFn, initialValue: string) => (itemsList: any[]): string => itemsList.reduce(replaceFn, initialValue);


const ContentLayout: FC<IContentLayout> = ({children}) => {
    const context = useContext(DashboardLayoutContext);
    
    const { setPathname = () => null } = context || {};
    const urlParams = useParams();
    const location  = useLocation();

    useEffect(() => {
        const selectedPath = pipe(
            toPairs,
            reducePath(replaceItem, location.pathname || ""),
            replace(new RegExp("/", "g"), " "),
            trim,
            replace(new RegExp(" ", "g"), ".")
        )(urlParams);

        setPathname(selectedPath);

    }, [urlParams, location, setPathname]);

    return (
        <>
            <Outlet context={[]} />
            {children}
        </>
    );
};

export default ContentLayout;
