import {useAuth0} from "@auth0/auth0-react";
import {Outlet, useLocation, useNavigate} from "react-router-dom";
import {useEffect} from "react";

const Root = () => {
  const {isAuthenticated, isLoading} = useAuth0();
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (!isLoading) {
      if (!isAuthenticated || location.pathname === '/') {
        if (location.pathname !== '/login') {
          navigate("/login");
        }
      }
      console.log("location: ", location)
    }
  }, [isAuthenticated, isLoading, location]);

  return (
    <>
      <Outlet />
    </>
  )
};

export default Root;
