import {Button, Card, Form, Input, Select, Switch} from "antd";
import ContentLayout from "components/layouts/ContentLayout";
import React, {useCallback, useState} from "react";
import PhoneNumberInput from "./components/PhoneNumberInput.component";
import {props} from "ramda";
import dayjs from "dayjs";

const defaultValues: {[key: string]: string} = {
    address: "*Dirección:* Pasaje Lisboa 103B, Hunter \n",
    reference: "*Referencia:* A 3 cuadras de la Comisaria de Hunter\n",
    maps: "*Ubicación:* \n https://goo.gl/maps/zC4MZ7Vk6p9W8cbS9 \n\n",
    fullName: "*Nombre y Apellidos:* Jonathan Huamani Cuadros\n",
    contactNumber: "*Celular:* 951 704 068\n",
    identity: "*DNI:* 46890151\n",
};

const bankInfo: {[key: string]: string} = {
    BCPPEN: `
    🔵*BCP Soles*🟠
    Cuenta Soles Arequipa: _21534748172058_
    CCI: _00221513474817205823_

    Cuenta Soles Lima: _19138508535008_.
    CCI: _00219113850853500854_.
    `,
    BCPUSD: `
    🔵*BCP Dólares*🟠
    Cuenta Dólares: _21506550915182_
    CCI: _00221510655091518227_
    `,
    IBKPEN: `
    🔵*IBK Soles*🟢
    Cuenta Ahorros Soles: _8983144681187_
    CCI: _00389801314468118742_
    `,
    IBKUSD: `
    🔵*IBK Dólares*🟢
    Cuenta Ahorros Dólares: _8983156807371_
    CCI: _00389801315680737145_
    `,
    BBVAPEN: `
    🔵*BBVA Soles*🟦
    Cuenta Soles: _0011-0220-0201792610_
    CCI: _011-220-000201792610-12_
    `,
    BBVAUSD: `
    🔵*BBVA Dólares*🟦
    Cuenta Dólares: _0011-0814-0214688676_
    CCI: _011-814-000214688676-13_
    `,
    SCOTIAPEN: `
    🔴*Scotiabank Soles*🔴
    Cuenta Soles: _7940463594_
    CCI: _00931420794046359460_
    `,
    SCOTIAUSD: `
    🔴*Scotiabank Dólares*🔴
    Cuenta Soles: _0023372536_
    CCI: _00920221002337253631_
    `,
    YAPE: `
    🟣*Yape*🟣: 951704068`,
    PLIN: `
    💚*Plin*💚: 951704068`
};

// const getDefaultValue = (value: string): string => value ? defaultValues[value] : "";

const getMessage = (fields: []) => props(fields, defaultValues).join("");

const getBankInfo = (fields: []) => props(fields, bankInfo).join("");

const whatsappDict = [
    {
        pattern: /\*(?<text>.*)\*/mg,
        html: "<strong>$1</strong>"
    },
    {
        pattern: /_(?<text>.*)_/mg,
        html: "<i>$1</i>"
    },
    {
        pattern: /~(?<text>.*)~/mg,
        html: "<del>$1</del>"
    },
    {
        pattern: /```(?<text>.*)```/mg,
        html: `<span class="font-mono ">$1</span>`
    },
    {
        pattern: /\n/mg,
        html: "<br />"
    }
];

const whatsapp2HTML = (text: string): string => whatsappDict.reduce((acc, current) => acc.replace(current.pattern, current.html), text);



const Whatsapp = () => {
    const [phoneNumber, setPhoneNumber] = useState('');
    const [message, setMessage] = useState('');

    const handleSubmitForm = useCallback(({ phoneNumber: phone, attachInfo = [], bankInfo = [], customMessage = "" }: {[key: string]: string | number | any } ) => {
        const whatsappApi = "http://api.whatsapp.com/send";

        const message = `${getMessage(attachInfo)}\n${getBankInfo(bankInfo)}\n\n${customMessage}`;

        const queryParams = new URLSearchParams({ phone, text: message }).toString();

        const url = `${whatsappApi}?${queryParams}`

        window.open(url, "_blank");

    }, []);

    const handleOnFieldsChange = useCallback((changedFields: any, allFields: any) => {
        const { phoneNumber: inputPhoneNumber, attachInfo = [], bankInfo = [], customMessage = "" } = allFields;
        const message = `${getMessage(attachInfo)}\n${getBankInfo(bankInfo)}\n\n${customMessage}`;
        setPhoneNumber(inputPhoneNumber);
        setMessage(whatsapp2HTML(message));
    }, []);

    return (
        <ContentLayout>
            <Card className="!m-8">
                <Form
                    labelCol={{ flex: '180px' }}
                    labelAlign="left"
                    labelWrap
                    wrapperCol={{ flex: 1 }}
                    colon={false}
                    layout="horizontal"
                    size="large"
                    onFinish={handleSubmitForm}
                    onValuesChange={handleOnFieldsChange}
                >
                    <Form.Item
                        label="Phone Number"
                        name="phoneNumber"
                        labelCol={{ xs: 12, sm: 12, md: 12, lg: 4 }}
                        rules={[
                            { required: true, message: "Please enter a valid Phone Number" },
                            { pattern: /^[0-9]{0,2}[0-9]{9}$/, message: "Phone number should have the format 999999999"}
                        ]}>
                        <PhoneNumberInput />
                    </Form.Item>
                    <Form.Item label="Attach info" name="attachInfo" labelCol={{ xs: 12, sm: 12, md: 12, lg: 4 }}>
                        <Select mode="multiple" placeholder="Select values to attach" allowClear>
                            <Select.Option value="address">Address</Select.Option>
                            <Select.Option value="reference">Reference</Select.Option>
                            <Select.Option value="maps">Map</Select.Option>
                            <Select.Option value="fullName">Fullname</Select.Option>
                            <Select.Option value="contactNumber">Contact Number</Select.Option>
                            <Select.Option value="identity">ID Number</Select.Option>
                        </Select>
                    </Form.Item>
                    <Form.Item label="Attach Bank info" name="bankInfo" labelCol={{ xs: 12, sm: 12, md: 12, lg: 4 }}>
                        <Select mode="multiple" placeholder="Select values to attach" allowClear>
                            <Select.Option value="BCPPEN">BCP Soles</Select.Option>
                            <Select.Option value="BCPUSD">BCP Dólares</Select.Option>
                            <Select.Option value="IBKPEN">IBK Soles</Select.Option>
                            <Select.Option value="IBKUSD">IBK Dólares</Select.Option>
                            <Select.Option value="BBVAPEN">BBVA Soles</Select.Option>
                            <Select.Option value="BBVAUSD">BBVA Dólares</Select.Option>
                            <Select.Option value="SCOTIAPEN">Scotiabank Soles</Select.Option>
                            <Select.Option value="SCOTIAUSD">Scotiabank Dólares</Select.Option>
                            <Select.Option value="YAPE">Yape</Select.Option>
                            <Select.Option value="PLIN">Plin</Select.Option>
                        </Select>
                    </Form.Item>
                    <Form.Item label="Custom Message" name="customMessage" labelCol={{ xs: 12, sm: 12, md: 12, lg: 4 }}>
                        <Input.TextArea allowClear showCount />
                    </Form.Item>
                    <div className="px-2 md:px-12">
                        <div><strong>Phone Number: </strong>{phoneNumber || "Not defined yet"}</div>
                        <div><strong>Message: </strong></div>
                        <div className="my-4 p-4 bg-slate-200 shadow-md rounded-lg bg-lime-100">
                            <div dangerouslySetInnerHTML={{ __html: message }} />
                            <p className="mt-2 mb-0 text-sm text-right italic text-gray-500">Creado hoy a las {dayjs().format('HH:mm A')}</p>
                        </div>
                    </div>
                    <Form.Item label=" ">
                        <Button type="primary" htmlType="submit">
                            Send Message
                        </Button>
                    </Form.Item>
                </Form>
            </Card>
        </ContentLayout>
    );
};

export default Whatsapp;
